:root {
  --steam-nametag-red: #bd4847;
  --steam-ingame-green: #90ba3c;
  --steam-online-blue: #57cbde;
  --steam-offline-gray: #898989;
  --steam-stattrak-orange: rgb(207, 106, 50);
  --steam-note-blue: rgb(153, 204, 255);
  --steam-knife-purple: rgb(134, 80, 172);
  --steam-souvenir-yellow: rgb(255, 215, 0);
  --steam-sale-golden: #e4ca63;
}

html, body {
  height: 100%;
  background-color: #1d2124;
}

#root {
  height: 100%;
  background-color: #1d2124;
  display: flex;
  flex-direction: column;
}

p, b, th, tr, ul, li, a, span, div {
  color: #e1e1e1;
}

a {
  color: var(--steam-souvenir-yellow);
}

a:hover {
  color: #e1e1e1;
  text-decoration: underline;
}

h1, h2, h3, h4, h5 {
  color: darkorange;
}

h1 {
  margin: 1rem;
  text-align: center;
}

.content {
  flex: 1 0 auto;
}

.buildingBlock {
  background-color: #23272b;
  padding: 1rem;
  margin: 1rem auto;
}

.orange {
  color: darkorange;
}

.yellow {
  color: var(--steam-souvenir-yellow);
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}